<template>
  <v-row
    align="center"
    class="pt-md-6"
    :style="{ paddingBottom: $vuetify.breakpoint.mdAndUp ? '60px' : '200px' }"
  >
    <v-col
      cols="12"
      md="6"
      offset-md="1"
    >
      <Calculator />
    </v-col>
  </v-row>
</template>

<script>
import Calculator from '@/components/Calculator.vue';

export default {
  name: 'Home',
  components: { Calculator },
};
</script>
