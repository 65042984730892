<template>
  <v-app>
    <v-system-bar
      color="#fff005"
      height="50"
      fixed
      light
      class="justify-center"
      @click="openLink()"
    >
      <span class="emoji">🤓</span>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        Looking for <strong>the best</strong> tips & tricks? Check our <strong>Jesmonite digital downloads</strong>. <a :href="link">Click here</a>
      </template>
      <template v-else>
        Click <a :href="link">here</a> for the best <strong>Jesmonite tips & tricks</strong>
      </template>
    </v-system-bar>

    <v-main>
      <component :is="layout">
        <router-view />
      </component>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      link: 'https://www.razzostudio.com/collections/jesmonite-digital-downloads',
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default';
    },
  },
  methods: {
    openLink() {
      window.location.href = this.link;
    },
  },
  metaInfo() {
    return {
      title: this.$t('MetaTitle'),
      description: this.$t('MetaDescription'),
    };
  },
};
</script>

<style lang="scss" scoped>
.v-system-bar {
  color: black !important;
  cursor: pointer;
  white-space: break-spaces;

  a {
    color: black;
  }
}

.v-main {
  margin-top: 50px;
}

.emoji {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  animation: 1s wiggle ease infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
