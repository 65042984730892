<template>
  <fragment>
    <v-row
      deck
      class="mt-n12"
    >
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column"
      >
        <v-card
          class="flex d-flex flex-column pa-8"
          style="position: relative; z-index: 2;"
        >
          <h3 class="mb-3 text-uppercase">
            Jesmonite Distributors
          </h3>
          <p class="mb-6">
            Check out a list of all official distributors on the Jesmonite website.
          </p>
          <div class="mt-auto">
            <v-btn
              block
              depressed
              href="https://jesmonite.com/distributors/"
              outlined
              text
              rounded
            >
              Find distributor
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column"
      >
        <v-card
          class="flex pa-8"
          style="position: relative; z-index: 2;"
        >
          <h3 class="text-uppercase">
            Best resources
          </h3>
          <v-list class="pb-0">
            <template
              v-for="(resource, index) in resources"
            >
              <v-list-item
                :key="`item-${index}`"
                :href="resource.link"
                target="_blank"
                class="px-0"
              >
                <v-list-item-action class="mr-3">
                  <v-btn
                    icon
                    x-small
                  >
                    <v-icon color="grey lighten-1">
                      {{ resource.icon }}
                    </v-icon>
                  </v-btn>
                </v-list-item-action>

                <v-list-item-content>
                  {{ resource.name }}
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < resources.length - 1"
                :key="`divider-${index}`"
              />
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column"
      >
        <v-card
          class="flex d-flex flex-column pa-8"
          style="position: relative; z-index: 2;"
        >
          <h3 class="mb-3 text-uppercase">
            Need some help?
          </h3>
          <p class="mb-6">
            Looking for a good sealer or new molds? Or maybe you want to brush up on your knowledge with an online workshop?
          </p>
          <v-row
            class="mt-auto"
            dense
          >
            <v-col cols="12">
              <v-btn
                block
                color="white"
                light
                depressed
                href="https://www.razzostudio.com/collections/workshops/"
                rounded
              >
                Book a workshop
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                depressed
                href="https://www.razzostudio.com/collections/creators/"
                outlined
                text
                rounded
              >
                Tools for creators
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-toolbar
      bottom
      class="align-self-end transparent my-6 mt-md-8 mb-md-3"
      fixed
      height="auto"
      style="color: rgba(255, 255, 255, 0.7);"
    >
      <v-row align="end">
        <v-col
          class="text-center text-md-left font-italic"
          cols="12"
          md="6"
        >
          <small v-html="$t('JesmoniteDisclaimer')" />
        </v-col>
        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          <div
            class="d-inline-flex align-center"
            style="font-size: 0.875rem;"
          >
            {{ $t('BuiltBy') }}
            <a
              href="https://www.razzostudio.com"
              class="ml-2"
            >
              <inline-svg
                :src="require('@/assets/razzo-studio-logo.svg')"
                :height="16"
                fill="rgba(255, 255, 255, 0.7)"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-toolbar>
  </fragment>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      resources: [
        {
          name: 'Razzo Studio on Youtube',
          link: 'https://www.youtube.com/channel/UCy6bsSzPaJIZvIkqByyK0cw',
          icon: 'fab fa-youtube',
        },
        {
          name: 'Jesmonite Official Creative Community',
          link: 'https://www.facebook.com/groups/jesmonitecreativecommunity',
          icon: 'fab fa-facebook',
        },
        {
          name: 'Jesmonite Tips and Tutorials',
          link: 'https://www.facebook.com/groups/501028694237915',
          icon: 'fab fa-facebook',
        },
        {
          name: 'Jesmonite Artists and Crafters',
          link: 'https://www.facebook.com/groups/315566356434795',
          icon: 'fab fa-facebook',
        },
      ],
    };
  },
};
</script>
