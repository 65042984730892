<template>
  <v-tooltip
    v-if="$te(label)"
    top
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="ml-1"
        fab
        :dark="!$vuetify.theme.dark"
        depressed
        height="16"
        :light="$vuetify.theme.dark"
        width="16"
        v-on="on"
      >
        <v-icon size="8">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t(label) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'fa-info',
    },
  },
};
</script>
