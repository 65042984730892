import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import i18n from '@/plugins/vue-i18n';
import '@/plugins/vue-fragment';
import '@/plugins/vue-google-adsense';
import '@/plugins/vue-gtm';
import '@/plugins/vue-hotjar';
import '@/plugins/vue-inline-svg';
import '@/plugins/vue-meta';
import LayoutDefault from '@/layouts/Default.vue';
import Loader from '@/components/Loader.vue';
import Tooltip from '@/components/Tooltip.vue';

import '@/scss/main.scss';

Vue.component('Default', LayoutDefault);
Vue.component('Loader', Loader);
Vue.component('Tooltip', Tooltip);

Vue.config.productionTip = false;

new Vue({
  i18n,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
