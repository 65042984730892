<template>
  <fragment>
    <v-container
      fluid
      class="pa-0"
      style="background: linear-gradient(120deg, rgb(183 138 155), rgb(193 124 150));"
    >
      <v-container>
        <Header />

        <router-view />
      </v-container>
    </v-container>

    <v-container>
      <Footer />
    </v-container>
  </fragment>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Default',
  components: {
    Header,
    Footer,
  },
};
</script>
