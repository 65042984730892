<template>
  <div
    class="d-flex align-center"
    :style="`height: ${height}px; width: 100%; overflow: hiddden`"
  >
    <v-progress-circular
      width="5"
      :color="color"
      indeterminate
      style="width: 100%"
    />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    height: {
      type: Number,
      default: 64,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
