<template>
  <v-toolbar
    class="align-self-start transparent pb-3 pt-md-3 text-uppercase"
    height="auto"
  >
    <h1 v-if="$vuetify.breakpoint.mdAndUp">
      {{ $t('PageTitle') }}
    </h1>
    <h2 v-else>
      {{ $t('PageTitle') }}
    </h2>
  </v-toolbar>
</template>

<script>

export default {
  name: 'Header',
  methods: {
    setTheme() {
      // eslint-disable-next-line no-multi-assign
      const dark = this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('dark', dark);
    },
  },
};
</script>
