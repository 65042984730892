<template>
  <v-card
    class="pa-6 pa-md-8"
  >
    <h2 class="mb-8 text-uppercase">
      {{ $t('CalculatorTitle') }}
    </h2>

    <div class="mb-6">
      <v-select
        id="product"
        v-model="product"
        color="white"
        item-color="white"
        hide-details
        :menu-props="{
          offsetY: true
        }"
        :items="products"
        item-text="name"
        :label="$t('Product')"
        outlined
        :placeholder="$t('ProductPlaceholder')"
        return-object
        rounded
        @change="clearValues"
      />
    </div>

    <v-row
      align="center"
      class="mb-4"
    >
      <v-col>
        <v-text-field
          id="powder"
          color="white"
          hide-details
          inputmode="numeric"
          :label="$t('Powder')"
          min="0"
          outlined
          pattern="\d*"
          :placeholder="$t('PowderPlaceholder')"
          rounded
          type="number"
          :value="powder"
          @keyup="calculate"
        />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="auto"
        class="px-1"
      >
        <v-icon>fas fa-plus</v-icon>
      </v-col>
      <v-col>
        <v-text-field
          id="liquid"
          color="white"
          hide-details
          inputmode="numeric"
          :label="$t('Liquid')"
          min="0"
          outlined
          pattern="\d*"
          :placeholder="$t('LiquidPlaceholder')"
          rounded
          type="number"
          :value="liquid"
          @keyup="calculate"
        />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="auto"
        class="pl-1"
      >
        <v-icon>fas fa-equals</v-icon>
      </v-col>
      <v-col cols="12">
        <v-text-field
          id="composite"
          color="white"
          hide-details="auto"
          inputmode="numeric"
          :label="$t('Composite')"
          min="0"
          outlined
          pattern="\d*"
          :placeholder="$t('CompositePlaceholder')"
          rounded
          type="number"
          :value="composite"
          @keyup="calculate"
        />
        <div
          v-if="pigment && product.name === 'AC100'"
          class="d-flex align-center mt-4"
        >
          <span
            class="mr-2"
            :style="{
              fontSize: '1.5rem'
            }"
          >🎨</span>
          <small v-html="$t('MaxPigmentAmount', { pigment })" />
        </div>
      </v-col>
    </v-row>

    <v-divider class="mb-6" />

    <div
      class="text-uppercase"
      v-html="$t('CalculateVolume')"
    />
    <div class="mb-3">
      <v-text-field
        id="water"
        color="white"
        hide-details="auto"
        :hint="$t('WaterHint')"
        inputmode="numeric"
        :label="$t('Water')"
        min="0"
        outlined
        :placeholder="$t('WaterPlaceholder')"
        rounded
        type="number"
        :value="water"
        @keyup="calculate"
      />
    </div>

    <div
      class="d-flex align-center justify-end text--disabled mt-6 mb-n1"
    >
      <div
        class="mr-2"
        v-html="$t('SupportUs')"
      />
      <inline-svg
        :src="require('@/assets/buy-me-a-coffee.svg')"
        :height="24"
      />
    </div>

    <div class="v-character">
      <loader
        v-if="!svgLoaded"
        color="#8e5056"
        :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
      />
      <inline-svg
        :src="require('@/assets/creative-guy.svg')"
        :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
        :width="$vuetify.breakpoint.mdAndUp ? 800 : 300"
        @loaded="svgLoaded = true;"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Calculator',
  data() {
    return {
      svgLoaded: false,
      water: undefined,
      composite: undefined,
      powder: undefined,
      liquid: undefined,
      pigment: undefined,
      product: undefined,
      products: [
        {
          name: 'AC100',
          compositeToWater: 1.75,
          powderToLiquid: 1,
          liquidToPowder: 2.5,
          pigment: 0.02,
        },
        {
          name: 'AC730',
          compositeToWater: 1.95,
          powderToLiquid: 1,
          liquidToPowder: 5,
          pigment: 0.02,
        },
      ],
    };
  },
  mounted() {
    [this.product] = this.products;
  },
  methods: {
    // eslint-disable-next-line consistent-return
    calculate(e) {
      const value = parseFloat(e.target.value);
      const { product } = this;
      const totalRatio = product.powderToLiquid + product.liquidToPowder;

      switch (e.target.id) {
        case 'water':
          this.composite = this.roundDecimals(value * product.compositeToWater);
          this.powder = this.roundDecimals((this.composite / totalRatio) * product.liquidToPowder);
          this.liquid = this.roundDecimals((this.composite / totalRatio) * product.powderToLiquid);
          this.pigment = this.roundDecimals(this.composite * product.pigment);
          break;
        case 'composite':
          this.water = this.roundDecimals(value * product.compositeToWater);
          this.powder = this.roundDecimals((value / totalRatio) * product.liquidToPowder);
          this.liquid = this.roundDecimals((value / totalRatio) * product.powderToLiquid);
          this.pigment = this.roundDecimals((this.powder + this.liquid) * product.pigment);
          break;
        case 'powder':
          this.liquid = this.roundDecimals(value / product.liquidToPowder);
          this.composite = this.roundDecimals(value + this.liquid);
          this.water = this.roundDecimals(this.composite / product.compositeToWater);
          this.pigment = this.roundDecimals(this.composite * product.pigment);
          break;
        case 'liquid':
          this.powder = this.roundDecimals(value * product.liquidToPowder);
          this.composite = this.roundDecimals(value + this.powder);
          this.water = this.roundDecimals(this.composite / product.compositeToWater);
          this.pigment = this.roundDecimals(this.composite * product.pigment);
          break;
        default:
          this.clearValues();
          break;
      }

      // Trigger GTM event
      this.$gtm.trackEvent({
        category: 'calculator',
        action: 'calculate',
        label: e.target.id,
      });
    },
    clearValues() {
      this.water = undefined;
      this.composite = undefined;
      this.powder = undefined;
      this.liquid = undefined;
      this.pigment = undefined;
    },
    roundDecimals(val) {
      return parseFloat(val.toFixed(2));
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-card > *:not(.v-character) {
    position: relative;
    z-index: 2;
  }

  .v-character {
    position: absolute;
    z-index: 1;
    bottom: -265px;

    @media all and (min-width: 960px) {
      bottom: unset;
      right: -615px;
      top: -80px;
    }
  }
</style>
